.graphSettingsRemoveButton {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border-radius: 50%;
    width: 1.7em;
    height: 1.7em;
}
.graphSettingsRemoveButton:hover {
    background: linear-gradient(45deg, rgb(255, 0, 0), rgb(255, 50, 50));
    cursor: pointer;
    user-select: none;
}
.graphSettingsRemoveButton svg g {
    stroke: black;
}
.graphSettingsRemoveButton:hover svg g {
    stroke: white;
}