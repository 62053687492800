.graphSettingsWrapper {
    width: 100%;
    height: 100%;
    background-color: white;
    overflow-y: auto;
}

.graphSettings {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1em;
    padding: 1em;
    margin-bottom: 2em;
}

.graphSettingsSectionLabel {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.3rem;
    font-weight: 400;
    padding-bottom: 0.75rem;
}
.graphSettingsSectionLabel .dx-button {
    margin-left: 0.5em;
}
.graphSettingsSectionLabel .dx-button-content {
    padding: 0.4em;
}

.emptySectionMessage {
    font-style: italic;
    color: rgb(146, 146, 146);
}

.dataSourceColumns {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.5em;
}

.sourceDataTablePopupWrapper .dx-popup-content {
    padding: 0rem;
}
  
.sourceDataTablePopupWrapper .dx-popup-content-scrollable {
    overflow: visible;
}

.graphTypeEntry {
    display: flex;
    flex-wrap: nowrap;
    gap: 0.5em;
    align-items: center;
}
.graphTypeEntry .dx-selectbox {
    width: 10em;
    min-width: 10em;
}

.graphTypeEntryInfo {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.graphSettings .readOnlyMessage {
    margin: 0.25em;
    font-style: italic;
    color: rgb(68, 68, 68);
}

.graphTypeNameAndIcon {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5em;
}

.graphTypePopoverWrapper .dx-popup-content {
    padding: 0em;
}