.graphSetManager {
    width: calc(100% - 2em);
    height: calc(100% - 2em);
    margin: 1em;
    display: flex;
    flex-flow: column;
    overflow: hidden;
}

.graphSetManagerHeader {
    margin: 0em;
    margin-bottom: 0.5em;
    flex: 0 0 auto;
    overflow: hidden;
}

.graphSetManagerHeader .dx-button {
    margin-left: 0.65em;
}

.graphSetManagerHeader .dx-button-content {
    padding: 0.4em;
}

.graphSetAndUploadSetLists {
    width: 100%;
    flex: 1 1 auto;
    margin: 0em;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 100%;
    overflow: hidden;
}

.graphSetList {
    width: calc(100% - 1em);
    height: 100%; 
    margin-right: 1em;
    border-width: 0.01em;
    border-color: gray;
    border-bottom-style: dotted;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
}

.graphSetListHeader {
    flex: 0 0 auto;
    width: 100%;
    overflow: hidden;
}

.graphSetListContent {
    flex: 1 1 auto;
    width: 100%;
    overflow-y: auto;
    background-color: rgb(252, 252, 252);
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 0.3em;
    padding-top: 0.3em;
    padding-bottom: 0.3em;
}

.graphSetSearchResults {
    padding: 0.5rem;
}

.graphSetListItem {
    flex: 0 0 auto;
    width: calc(100% - 0.5em);
    border-style: solid;
    border-width: 0.1em;
    border-radius: 0.5em;
    padding: 0.5em;
    padding-bottom: 0.25em;
    display: flex;
    flex-flow: column;
    text-align: left;
    overflow: hidden;
    cursor: pointer;
}
.graphSetListItem:hover {
    filter: brightness(98%);
}

.graphSetListItemTitle {
    flex: 0 1 auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
    white-space: nowrap;
}

.graphSetListItemTitle b {
    text-overflow: ellipsis;
    overflow: hidden;
}

.graphSetListItemTitle .sharedIndicator {
    height: 1.2rem;
}

.graphSetListItemTitle .sharedIndicator span {
    margin-right: 0.2rem;
    font-style: italic;
    font-size: 0.8rem;
    line-height: 100%;
}

.graphSetListItemTitle .sharedIndicator i {
    vertical-align: middle;
}

.graphSetListItemDescription {
    flex: 0 1 auto;
    width: 100%;
    overflow: hidden;
}

.graphSetListItemDescriptionLine {
    margin: 0em;
    margin-left: 0.25em;
    margin-top: 0.25em;
    overflow: hidden;
}

.graphSetListItemDropIndicator {
    margin: 0.25em;
    padding: 1em;
    width: calc(100% - 0.5em);
    min-height: 4em;
    border-style: solid;
    border-width: 0.1em;
    border-radius: 0.5em;
    border-color: #ddd;
    background: repeating-linear-gradient(
        45deg,
        #eaeaea55,
        #eaeaea55 1em,
        #e1e1e155 1em,
        #e1e1e155 2em
      );
    text-align: center;
    color: gray;
    font-style: italic;
    font-size: small;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    overflow: hidden;
}

.graphSetUploadSets {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    background-color: rgb(252, 252, 252);
    border-style: solid;
    border-width: 0.01em;
    border-color: gray;
    border-radius: 0.5em;
    overflow: hidden;
    position: relative;
}

.graphSetUploadSetsHeader {
    padding:  0.75em;
    padding-bottom: 0.5em;
    margin: 0em;
    width: 100%;
    flex: 0 1 auto;
    overflow: hidden;
    word-wrap: break-word;
}

.graphSetUploadSetsHeader .dx-button {
    margin-left: 0.65em;
}

.graphSetUploadSetsHeader .dx-button-content {
    padding: 0.4em;
}

.graphSetUploadSetsContent {
    width: calc(100% - 1.5em);
    flex: 1 1 auto;
    margin: 0.75em;
    margin-top: 0em;
    border-style: solid;
    border-width: 0.01em;
    border-color: #ddd;
    border-radius: 0.5em;
    overflow: hidden;
    background: repeating-linear-gradient(
        45deg,
        #eaeaea55,
        #eaeaea55 3em,
        #e1e1e155 3em,
        #e1e1e155 6em
      );
    position: relative;
}

.graphSetFolderTemplate {
    flex: 0 0 auto;
    color: black;
    width: calc(100% - 0.5em);
    padding: 1em;
    border-style: solid;
    border-width: 0.08em;
    border-radius: 0.5em;
    border-color: darkgray;
    border-style: solid;
    background:white;
    user-select: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}
.graphSetFolderTemplate:hover {
    filter: brightness(98%);
}

.graphSetFolderTemplate span {
    margin-left: 0.5em;
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
}

.graphSetManager .canDropInto {
    background: repeating-linear-gradient(
        45deg,
        #eaeaea88,
        #eaeaea88 1rem,
        #e1e1e188 1rem,
        #e1e1e188 2rem
      );
}