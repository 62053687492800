.createTemplateOptions {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.createTemplateOptions .templateNameBox {
    margin-bottom: 0.5rem;
    flex: 0 1 auto;
}

.createTemplateOptions .templateDescriptionBox {
    flex: 1 1 auto;
}