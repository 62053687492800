.graphSetFolderBreadcrumbs {
    display: flex;
    background: whitesmoke;
    padding: 0.2rem;
    margin-bottom: 0.5rem;
    border-width: 0.01rem;
    border-radius: 0.25rem;
    border-color: gray;
    border-style: solid;
    user-select: none;
    width: 100%;
    flex-wrap: wrap;
}

.graphSetFolderBreadcrumbs .breadCrumbButton {
    max-width: 10rem;
}

.graphSetFolderBreadcrumbs .breadCrumbButton .dx-button-content {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    text-overflow: ellipsis;
}

.graphSetFolderBreadcrumbs .breadCrumbArrow {
    border-style: none;
    background-color: transparent;
}

.graphSetFolderBreadcrumbs .breadCrumbArrow .dx-button-content {
    margin: 0.025rem;
    padding: 0rem;
}

.graphSetFolderBreadcrumbs .breadCrumbArrow .dx-button-content .dx-icon {
    opacity: 1;
}