.feaLoadPropertyPopup {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.feaLoadPropertyTopOptions {
    display: flex;
    flex-direction: row;
    margin: 0.5rem;
}
.feaLoadPropertyTopOptions .dx-radiogroup.dx-state-readonly .dx-radiobutton-icon::before {
    border-color: lightgray;
}
.feaLoadPropertyNumberInput {
    flex: 0 1 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.5rem;
}
.feaLoadPropertyTopOptions h4 {
    padding: 0rem;
    margin: 0rem;
    margin-right: 0.5rem;
    font-size: 1rem;
}

.feaLoadPropertyContent {
    flex: 1 1 auto;
    overflow-y: hidden;
}
.feaLoadPropertyContent .dx-checkbox {
    margin: 0.5rem;
}
.feaLoadPropertyContent .dx-checkbox.dx-state-readonly .dx-checkbox-icon {
    border-color: lightgray;
}
.feaLoadPropertyContent h3 {
    font-size: 1.2rem;
    margin: 0rem;
}

.feaLoadPropertyBox {
    border-width: 0.05rem;
    border-color: lightgray;
    border-style: solid;
    background-color: rgb(252, 252, 252);
    margin: 0.5rem;
    margin-bottom: 0.75rem;
}