.savedGraphsList {
    padding: 0.5em;
    padding-right: 0.3em;
    padding-top: 0.1em; 
    padding-bottom: 0.5em;
    width: calc(100% - 0.2em);
    height: 100%; 
    margin: 0;
    border-bottom-style: dotted;
    border-bottom-width: 0.05em;
    flex:1;
    display:flex;
    flex-direction:column;
    overflow-y: auto;
    overflow-x: hidden;
    gap: 0.5em;
    align-items: center;
}

.savedGraphTemplate {
    color: black; 
    text-overflow: ellipsis;
    width: 100%;
    padding: 0.4rem;
    border-width: 0.04rem;
    border-radius: 0.4rem;
    border-color: black;
    border-style: solid;
    background:white;
    user-select: none;
    position: relative;
    cursor: pointer;
}
.savedGraphTemplate:hover {
    opacity: 0.9;
}

.savedGraphTemplate h5 {
    width: 100%;
    text-overflow: ellipsis;
    text-align: left;
    word-wrap: break-word;
    padding: 0em;
    margin: 0em;
    font-weight: normal;
    overflow: hidden;
}

.selectedSavedGraph {
    background:#D0E9FF !important;
}

.savedGraphTemplate .sharedIndicator {
    position: absolute;
    bottom: 0.4rem;
    right: 0.4rem;
    backdrop-filter: blur(0.2rem);
}

.savedGraphTemplate .sharedIndicator span {
    margin-right: 0.2rem;
    font-style: italic;
    font-size: 0.7rem;
    line-height: 100%;
}

.savedGraphTemplate .sharedIndicator i {
    vertical-align: middle;
}

.graphFolderTemplate {
    color: black;
    width: 100%;
    padding: 1em;
    border-width: 0.1em;
    border-radius: 0.4em;
    border-color: black;
    border-style: solid;
    background:white;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.graphFolderTemplate:hover {
    opacity: 0.9;
}

.graphFolderTemplate span {
    margin-left: 0.5em;
    overflow: hidden;
    text-overflow: ellipsis;
}

.canDropInto {
    background: repeating-linear-gradient(
        45deg,
        #eaeaea,
        #eaeaea 1em,
        #e1e1e1 1em,
        #e1e1e1 2em
      );
}

.graphFolderTemplate .dx-icon {
    float: left;
}