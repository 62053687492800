.graphSettingsAddButton {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(45deg, green, rgb(46, 189, 46));
    border-radius: 50%;
    width: 1.7em;
    height: 1.7em;
}
.graphSettingsAddButton:hover {
    background: linear-gradient(45deg, rgb(1, 173, 1), rgb(57, 235, 57));
    cursor: pointer;
    user-select: none;
}