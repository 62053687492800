.colorBySettingsSeriesList {
    display: flex;
    flex-direction: column;
    gap: 0.25em;
    align-items: baseline;
    min-width: 16em;
    max-height: 40em;
}

.colorBySeriesItem {
    display: flex;
    flex-direction: row;
    gap: 0.5em;
    align-items: center;
    padding: 0.25em;
    width: 100%;
}
.colorBySeriesItem:hover {
    cursor: pointer;
    color: #347AB7;
    background-color: lightblue;
    border-radius: 0.25em;
}

.colorBySeriesItemIcon {
    min-width: 1.5em;
    min-height: 1.5em;
    background-color: gray;
}

.colorBySeriesItemLabel {
    max-width: 35em;
    text-wrap: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
}

.colorBySettingsPickerWrapper .dx-popup-content {
    padding: 0em;
}

.colorBySettingsPicker .chrome-picker {
    box-shadow: none !important;
    width: 100%;
    height: 100%;
}