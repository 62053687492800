.generalLoadPropertyPopup {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.generalLoadPropertyTopOptions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0.5rem;
}
.generalLoadPropertyTopOptions .dx-radiogroup.dx-state-readonly .dx-radiobutton-icon::before {
    border-color: lightgray;
}
.generalLoadPropertyTopOptions .dx-checkbox.dx-state-readonly .dx-checkbox-icon {
    border-color: lightgray;
}

.generalLoadPropertyTopOptions h4 {
    padding: 0rem;
    margin: 0rem;
    margin-right: 0.5rem;
    font-size: 1rem;
}
.generalLoadPropertyRadioButtons {
    flex: 0 1 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.5rem;
}
.generalLoadPropertyShowThermal {
    flex: 0 1 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.5rem;
}
.generalLoadPropertyShowThermal h4 {
    margin-right: 0rem;
    margin-left: 0.5rem;
}

.generalLoadPropertyContent {
    flex: 1 1 auto;
    overflow-y: hidden;
}
.generalLoadPropertyContent .dx-datagrid {
    border-color: lightgray;
    border-style: solid;
    border-width: 0.05rem;
}
.generalLoadPropertyContent .dx-datagrid .dx-header-row .dx-datagrid-text-content {
    color: black;
}
.generalLoadPropertyContent .dx-datagrid .dx-data-row > td {
    vertical-align: middle!important; 
    padding: 0rem;
}
.generalLoadPropertyContent .dx-datagrid .dx-header-row > td {
    vertical-align: middle!important;
    font-weight: 500;
}
.generalLoadPropertyContent .dx-datagrid td[role=columnheader] {  
    text-align: center!important;
}

.loadChoiceTable {
    width: 100%;
    height: 100%;
}
.loadChoiceTable td {
    text-align: center;
    padding: 0.25rem;
    height: 1.75rem;
}

.loadCategoriesTable {
    width: 100%;
    border-collapse: collapse;
}
.loadCategoriesTable td {
    border: 0.05rem solid lightgray;
    padding: 0.25rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    color:#4AB7D0;
    font-weight: 400;
}