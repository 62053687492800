.userListItem {
    width: 100%;
    display: flex;
}

.userListItemLeftDiv {
    width: 80%;
    display: flex;
    align-items: center;
}

.userListItemRightDiv {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content:flex-end
}

.userListItemRightDiv .dx-button {
    margin-left: 0.5vw;
}

.roleName {
    font-style: italic;
}