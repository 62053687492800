.columnBoxDroppable {
    padding: 0.4em;
    padding-left: 0.4em;
    padding-right: 0.4em;
    border: dashed 0.1em gray;
    border-radius: 1em;
    background-color: white;
    color: gray;
    font-style: italic;
    user-select: none;
    width: fit-content;
    display: flex;
    flex-direction: row;
    flex-shrink: 2;
    gap: 0.25em;
    align-items: center;
}

.columnBoxDroppableIsOver {
    background: repeating-linear-gradient(45deg, 
        rgb(230, 230, 230), rgb(230, 230, 230) 1em, 
        rgb(220, 220, 220) 1em, rgb(220, 220, 220) 2em);
}