.inviteToastVertContent {
    width: 100%;
    height: 3.9rem;
    background-color: #337AB7;
    border-radius: 0.5rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.inviteToastHorizContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}
.inviteToastIcon {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-left: 0.5rem;
}
.inviteToastIcon > span {
    font-size: 1.6rem;
}

.inviteToastText {
    vertical-align: middle;
    padding: 0.5rem;
    font-size: 1.1em;
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
}
.inviteToastButtons {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.inviteToastButtons .dx-button {
    margin: 0.5rem;
    margin-right: 0.25rem;
    margin-left: 0.25rem;
}
.inviteToastButtons .dx-button-content {
    padding: 0.5rem;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
}

.inviteToastLoadingBar {
    height: 0.3rem;
    width: 100%;
}
.inviteToastLoadingBar > div {
    animation: inviteToastLoadingBarFill 10s linear 1;
    animation-fill-mode: forwards;
    height: 100%;
    background-color: lightgray;
}

@keyframes inviteToastLoadingBarFill {
    0% {
      width: 100%;
    }
    100% {
      width: 0%;
    }
}