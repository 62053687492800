.mobileViewer {
    width: 100%;
    height: 100%;
    max-height: 100dvh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.mobileViewer .mobileViewerHeader {
    flex: 0 1 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.25rem;
    gap: 0.5rem;
    background-color: white;
    font-weight: bold;
    font-size: 1.1em;
}

.mobileViewer .mobileViewerBody {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    overflow: hidden;
    padding: 0.5rem 1.2rem;
    overflow-y: auto;
}

.mobileViewer .mobileViewerNavigation {
    background-color: #337AB7;
}

.mobileViewer .emptyMessage {
    color: rgb(231, 231, 231);
    font-size: 1.2em;
    padding: 0.5rem;
}