.failureModePopup {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.failureModePopup .dx-textbox {
    margin-bottom: 0.75rem;
}

.failureModeCriterionTable {
    flex: 1 1 auto;
}

.failureModeCriterionTable .dx-datagrid {
    border-width: 0.05rem;
    border-style: solid;
    border-color: lightgray;
}