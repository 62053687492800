.graphCompaniesTagBox .dx-tag-remove-button:before {
    content: none;
} 
.graphCompaniesTagBox .dx-tag-remove-button:after {
    content: none;
} 

.sharingListItem {
    width: 100%;
    display: flex;
}

.sharingListItemLeftDiv {
    width: 50%;
    display: flex;
    align-items: center;
}

.sharingListItemRightDiv {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content:flex-end
}

.sharingListItemRightDiv .dx-button {
    margin-left: 0.5vw;
}

.graphShareToCategory .dx-list-search {
    margin-top: 0em;
    margin-bottom: 0em;
}

.graphShareToCategory .dx-selectbox {
    margin-top: 0em;
    margin-bottom: 0em;
}

.graphShareToCategory .dx-accordion-item-body {
    padding: 0.5em;
    padding-bottom: 0.75em;
}

.graphShareToCategory .dx-list-group-header {
    padding-top: 0.75em;
}

.graphShareToCategory .dx-button-content {
    padding: 0.65vh !important;
}

.shareGraphWithPopupContent {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.shareGraphWithPopupContent .dx-list {
    flex: 1 1 auto;
}

.shareGraphWithList .dx-popup-content {
    padding-bottom: 0rem;
}
  
.shareGraphWithList .dx-popup-content-scrollable {
    overflow: visible;
}

.exportImageFileSettings {
    display: flex;
    flex-direction: row;
    padding-bottom: 1em;
    gap: 0.5em;
}