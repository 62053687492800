.quickGraphPopup {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
}
.quickGraphPopup .dx-label span {
    color: black !important;
}

.quickGraphTemplateList {
    flex: 0 1 auto;
    width: 100%;
    height: 100%;
    background-color: rgb(235, 235, 235);
    display: flex;
    flex-direction: column;
}

.quickGraphTemplateListScrollView {
    flex: 1 1 auto;
    margin-right: 0.1rem;
}

.quickGraphTemplateList .dx-textbox {
    margin: 0.3rem;
    margin-bottom: 0rem;
}

.fullyRelevantGraphTemplate {
    border-left-color: #006400 !important;
}
.partiallyRelevantGraphTemplate {
    border-left-color: #D2691E !important;
}
.notRelevantGraphTemplate {
    border-left-color: #A52A2A !important;
}

.quickGraphTemplateListItem {
    background-color: white;
    border: gray solid 0.04rem;
    border-radius: 0.15rem;
    margin: 0.3rem;
    padding: 0.5rem;
    border-left-width: 0.15rem !important;
}
.quickGraphTemplateListItem:hover {
    border-color: black;
    background-color: rgb(245, 245, 245);
    color: black;
    cursor: pointer;
}
.quickGraphTemplateListItem h3 {
    font-size: 1em;
    font-weight: 500;
    margin: 0rem;
    padding: 0rem;
}
.quickGraphTemplateListItem p {
    font-style: italic;
    margin: 0.25rem;
    font-size: 0.85em;
}
.templateRelevanceScore {
    font-weight: 700;
}

.quickGraphRightContent {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
}

.quickGraphPreview {
    flex: 1 1 auto;
    position: relative;
}

.quickGraphBottomBar {
    flex: 0 1 auto;
    margin: 0.5rem;
    display: flex;
    flex-direction: row-reverse;
}
.quickGraphCreateButton {
    flex: 0 1 auto;
    margin-left: 0.5rem;
    margin-top: 7.5px;
}
.quickGraphBottomBarLeftContent {
    flex: 1 1 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-gap: 0.5rem;
}
.quickGraphNameBox {
    grid-column: 2;
    height: calc(100% - 7.5px);
}
.quickGraphTagBox {
    grid-column: 1;
    height: calc(100% - 7.5px);
}

.quickGraphBigGraphSetSelector {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.quickGraphBigGraphSetSelectorContent {
    width: 100%;
    height: 100%;
    margin: 1rem;
    display: flex;
    flex-direction: column;
}
.quickGraphBigGraphSetSelectorContent h1 {
    margin: 0.75rem;
    flex: 0 1 auto;
}
.quickGraphBigGraphSetSelectorContent .quickGraphTree {
    flex: 1 1 auto;
    height: 100%;
}

.quickGraphNoGraphSetsSelectedMessage {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    overflow: hidden;
    padding: 2rem;
    color: rgb(68, 68, 68);
    font-size: 1.2rem;
}

.quickGraphPopup .dx-tabpanel {
    flex: 0 1 auto;
    width: 21em;
}
.quickGraphPopup .dx-tabpanel .dx-tabs-wrapper {
    width: 100%;
    display: inline-table;
}
.quickGraphPopup .dx-tabpanel .dx-tab {
    width: 50%;
    padding: 0.25rem;
    box-shadow: none !important;
}
.quickGraphPopup .dx-tabpanel .dx-tab-selected .dx-tab-content {
    color:white !important;
}

.dx-tabpanel.dx-state-focused .dx-multiview-wrapper {  
    border-color: #ddd!important;  
}

.templateListInfoMessage {
    width: 100%;
    text-align: center;
    overflow: hidden;
    padding: 1rem;
    color: rgb(68, 68, 68);
    font-size: 0.9rem;
}

.templatesFormInfoHighlight {
    color: #337AB7;
    font-weight: 600;
}