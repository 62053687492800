.mainProgramsDiv {
    display: flex;
    height: 100%;
}

.mainProgramsDiv .dx-list {
    height: 90%;
}

.mainProgramsDiv h3 {
    margin-top: 0vh;
}

.mainProgramsDiv .dx-button-content {
    padding: 0.65vh !important;
}

.programsDiv {
    width: 50%;
}

.programsDiv .dx-empty-message {
    white-space: pre-wrap;
    color: #a0a0a0;
}

.programUsersDiv {
    padding-left: 1vw;
    width: 50%;
}

.programListItem {
    width: 100%;
    display: flex;
}

.programListItemLeftDiv {
    width: 80%;
    display: flex;
    align-items: center;
}

.programListItemRightDiv {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content:flex-end
}

.companyName {
    font-style: italic;
}

.createProgramDiv .dx-label {
    color: black;
}

.createProgramPopupWrapper .dx-popup-content {
    padding-bottom: 0rem;
}
  
.createProgramPopupWrapper .dx-popup-content-scrollable {
    overflow: visible;
}