.designPropertyPopup {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.designPropertyPopup .dx-tab-selected .dx-tab-text {
    color: white;
}

.designPropertyAssignZonesDiv {
    flex: 0 1 auto;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.5rem;
}

.designPropertyAssignZonesDiv h4 {
    padding: 0rem;
    margin: 0rem;
    margin-right: 0.5rem;
    font-size: 1rem;
}

.designPropertyAssignZonesDiv .dx-textbox {
    flex: 1 1;
}

.designPropertyPopupMainContent {
    flex: 1 1 auto;
    overflow-y: hidden;
}

.designPropertyPopupMainContent .dx-datagrid {
    border-top-color: lightgray;
    border-top-style: solid;
    border-top-width: 0.05rem;
}

.designPropertyPopupMainContent .dx-checkbox.dx-state-readonly .dx-checkbox-icon {
    border-color: lightgray;
}

.designPropertySizingTabContent {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
}

.designPropertySizingTabTable {
    flex: 1 1 auto;
    overflow-y: hidden;
}

.designPropertyAnalysisModes {
    flex: 0 1 auto;
    display: flex;
    flex-direction: row;
}

.designPropertyAnalysisModes .dx-selectbox {
    margin: 0.25rem;
    width: 8rem;
}

.designPropertyPopupFooter {
    display: flex;
    justify-content: space-between;
    margin-top: 0.25rem;
}

.designPropertyPopupRequestedDesigns {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
}

.designPropertyPopupRequestedDesigns h4 {
    padding: 0rem;
    margin: 0rem;
    margin-right: 0.5rem;
    font-size: 1rem;
}

.designPropertyDisabledCell {
    background-color: #f7f7f7;
}