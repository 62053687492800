.analysisPropertyPopup {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.analysisPropertyPopup h3 {
    margin: 0rem;
    margin-top: 0.75rem;
}

.analysisPropertyFailureModes {
    flex: 1 1 auto;
    margin: 0.5rem;
    overflow-y: hidden;
    border-bottom-style: dashed;
    border-bottom-width: 0.05rem;
    border-bottom-color: lightgray;
}

.failureModeListItem {
    border-color: lightgray;
    border-width: 0.05rem;
    border-style: solid;
    margin-bottom: 0.5rem;
    padding: 0.75rem;
    display: flex;
    flex-direction: column;
}

/* PG Note: disabling this for now until the failure mode form is ready. */
/* .failureModeListItem:hover {
    background-color: lightgray;
    border-color: gray;
    cursor: pointer;
} */