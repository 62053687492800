.p-l-10 {
  padding-left: 10px;
}

.p-l-r-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.dx-drawer-expand.dx-drawer-right .panel-list {
  float: right;
}

label {
  font-weight: bold;
}

/* 1500 is the z-index for a DevExtreme popup. So tooltips need to have a z-index greater than 1500 to appear in popups. */
.dxc-tooltip {
  z-index: 1600;
}