.graphSetSelectorDropdownButtonDiv .dx-button {
    border-style: none;
    border-radius: 1rem;
    width: 2rem;
    height: 2rem;
    padding: 0rem;
}

.graphSetSelectorDropdownButtonDiv .dx-button .dx-icon {
    color: #999999;
}

.graphSetSelectorDropdownButtonDiv .dx-label span {
    color: black !important;
}

.graphSetSelectorDropdownBox {
    height: 100%;
}

.graphSetSelectorDropdown {
    height: 100%;
    min-height: 10rem;
}
