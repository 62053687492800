.landingPageDiv {
    height: 100%;
    width: 100%;
}

.landingPageDiv h1 {
    padding: 0px;
    margin: 0px;
}

.welcomeMessageDiv {
    position: absolute;
    z-index: 2;
    text-align: center;
    background-color: #FFFFFFDD;
    border-radius: 0.5em;
    top: 50%;
    left: 50%;
    width: 36em;
    height: 24em;
    padding: 2em;
    margin-top: -16em;
    margin-left: -19em;
}

.landingPageChartDiv {
    position: absolute; 
    z-index: 1;
    height: calc(100% - 3.3em);
    width: 100%;
}

.landingPageDiv .dx-button {
    width: 8em;
    height: 3em;
}

.unauthorizedText {
    color: gray;
    font-size: 2vh;
    text-align: left;
}

.landingPageDiv a {
    margin: 0.5rem;
}