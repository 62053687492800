.boltedLoadPropertyPopup {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.boltedLoadPropertyTopOptions {
    display: flex;
    flex-direction: row;
    margin: 0.5rem;
    align-items: center;
}
.boltedLoadPropertyTopOptions .dx-checkbox.dx-state-readonly .dx-checkbox-icon {
    border-color: lightgray;
}
.boltedLoadPropertyTopOptions h4 {
    padding: 0rem;
    margin: 0.5rem;
    font-size: 1rem;
}

.boltedLoadPropertyContent {
    flex: 1 1 auto;
    overflow-y: hidden;
}
.boltedLoadPropertyContent .dx-datagrid {
    border-color: lightgray;
    border-style: solid;
    border-width: 0.05rem;
}
.boltedLoadPropertyContent .dx-datagrid .dx-header-row .dx-datagrid-text-content {
    color: black;
}
.boltedLoadPropertyContent .dx-datagrid .dx-header-row > td {
    vertical-align: middle!important;
    font-weight: 500;
}
.boltedLoadPropertyContent .dx-datagrid td[role=columnheader] {  
    text-align: center!important;
}

.boltedLoadPropertyPopup .loadCategoriesTable {
    width: 100%;
    border-collapse: collapse;
}
.boltedLoadPropertyPopup .loadCategoriesTable td {
    border: 0.05rem solid lightgray;
    padding: 0.25rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    color:#4AB7D0;
    font-weight: 400;
}