.graph {
    height: 100%;
    width: 100%;
    position: relative;
}

.chartWrapper {
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.graphHelpButton {
    position: absolute;
    top: 0em;
    left: 0em;
    z-index: 2;
    margin: 0.25rem;
    font-size: 1.2rem;
}

.graphTooltip {
    display: flex;
    flex-direction: column;
    gap: 0.25em;
    font-size: 1.1em;
}

.graphTooltipHeader {
    font-size: 1.2em;
    font-weight: 500;
    padding-bottom: 0.25em;
}

.graphTooltipLabel {
    font-weight: 500;
}