.bondedLoadPropertyPopup {
    display: flex;
    flex-direction: column;
    height: 100%;
}
.bondedLoadPropertyPopup h4 {
    padding: 0rem;
    margin: 0.5rem;
    font-size: 1rem;
}

.bondedLoadPropertyTopOptions {
    display: flex;
    flex-direction: row;
    margin: 0.5rem;
    align-items: center;
}

.bondedLoadPropertyTopNumberBoxes {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    padding-bottom: 0.5rem;
}

.bondedLoadPropertyTopNumberBox {
    display: flex;
    flex-direction: row;
    padding: 0.25rem;
}
.bondedLoadPropertyTopNumberBox h4 {
    margin-left: 0rem;
}
.bondedLoadPropertyTopNumberBox .dx-numberbox {
    width: 6rem;
}

.bondedLoadPropertyContent {
    flex: 1 1 auto;
    overflow-y: hidden;
}
.bondedLoadPropertyContent .dx-datagrid {
    border-color: lightgray;
    border-style: solid;
    border-width: 0.05rem;
}
.bondedLoadPropertyContent .dx-datagrid .dx-header-row .dx-datagrid-text-content {
    color: black;
}
.bondedLoadPropertyContent .dx-datagrid .dx-header-row > td {
    vertical-align: middle!important;
    font-weight: 500;
}
.bondedLoadPropertyContent .dx-datagrid .dx-data-row > td {
    vertical-align: middle!important; 
    padding: 0rem;
}
.bondedLoadPropertyContent .dx-datagrid td[role=columnheader] {  
    text-align: center!important;
}