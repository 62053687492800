.templatePreviewWithButtonDiv {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.templatePreviewDiv {
    background-color: white;
    padding: 0.25em;
    margin: 0.25em;
    height: 100%;
    flex: 1 1;
}