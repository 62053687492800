.dashboardDiv {
    height: 100%;
}

.logoHeaderImage {
    height: 2.2em;
}

.imageButton {
    padding: 0vh !important;
    margin-left: 0.5vw;
}

.imageButton .dx-button-content {
    padding: 0vh !important;
}

.toggleSidebarButton .dx-button {
    padding-left: 0.5vw !important; 
    margin-right: 0vw !important;
    padding-right: 0vw !important; 
}

.toggleSidebarButton {
    padding-right: 0vw !important;
}

.mainDashboardContentDiv {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 1vh !important;
}

.dx-tab-selected {  
    background: #337AB7 !important;
}  

.dx-tab-selected .dx-tab-text {
    color: white !important;
}

.dx-tab-selected .dx-icon {  
    color: white !important;
}  

.accountButton {
    padding-right: 1vw !important;
}

.headerToolbar {
    background-color: white;
    height: 3.3em;
    position: fixed;
    z-index: 999;
}

.headerToolbar .dx-toolbar-items-container {
    height: 100%;
}

.headerToolbar .dx-tabs {
    font-size: 14px;
}

.headerToolbar .dx-tab {
    width: 24rem;
}

.headerToolbar .dx-button {
    background-color: rgba(0, 0, 0, 0);
    border: none;
    font-size: 14px;
    padding-left: 0.5vw;
    padding-right: 0.5vw;
}

.headerToolbar .dx-icon {
  font-size: 1.3em;  
  width: auto;  
  height: auto;  
  line-height: normal;  
}

.belowToolbar {
  padding-top: 3.3em;
  height: calc(100% - 3.3em);
}

.notificationSymbol {
    display: inline-block;
    background-color: red;
    min-width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
    color:white;
    font-size: 0.75rem;
    text-align: center;
    vertical-align: middle;
    overflow: hidden;
}

.accountButtonDropDown .dx-badge {
    background-color: red;
}

.notificationWrapper {
    width: 0rem;
    height: 0rem;
    margin-left: 0.1rem;
    transform: translateY(0.1rem);
}