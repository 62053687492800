.setManager {
    width: 100%;
    height: calc(100% - 1em);
    user-select: none;
    display: grid;
    grid-template-columns: 33.33% 66.66%;
    grid-template-rows: 100%;
    overflow: hidden;
}

.uploadSetFilterableListSection {
    background-color: white;
    width: calc(100% - 1.5em);
    height: calc(100% - 1em);
    margin: 1em;
    margin-top: 0em;
    margin-right: 0.5em;
    overflow: hidden;
}

.graphSetSection {
    background-color: white;
    width: calc(100% - 1.5em);
    height: calc(100% - 1em);
    margin: 1em;
    margin-top: 0em;
    margin-left: 0.5em;
    overflow: hidden;
}

.setsEmptyMessage {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    overflow: hidden;
    padding: 2rem;
    opacity: 0.5;
}