.graphsPage {
    width: 100%;
    height: 100%;
    display: flex;
}

.noGraphsMessage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: gray;
    gap: 1em;
    user-select: none;
}

.noGraphsMessage > h3 {
    font-size: large;
    margin: 0em;
}

.customChartSidebarDrawer {
    height: 100%;
}