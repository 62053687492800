.chartDiv {
    width: 100%;
    height: 100%;
    position: relative;
}

.chartExportButton {
    position: absolute;
    top: 0em;
    right: 0em;
    z-index: 2;
}

.chartHelpButton {
    position: absolute;
    top: 0em;
    left: 0em;
    z-index: 2;
    margin: 0.25rem;
    font-size: 1.2rem;
}

.chart {
    background-color: white;
    height: 100%; 
    z-index: 1;
}

.chart .dxc-title text {
    fill: rgb(43, 43, 43) !important;
}

.chart .dxc-axes-group text {
    fill: rgb(43, 43, 43) !important;
}

.chart .dxc-elements-axes-group text {
    fill: rgb(43, 43, 43) !important;
}

.rightSidebarOpenButton {
    position: absolute;
    top: calc(50% - (6rem/2));
    right: calc(30rem - 0.4rem);
    height: 6rem;
    width: 3rem;
}

.chartRightDrawerDiv {
    height: calc(100% - 3.3em);
    width: 30rem;
    padding-right: 1em;
    padding-top: 1em;
    position: fixed;
    display: flex;
    flex-direction: column;
    flex: 1 1;
}

.chartRightDrawerDiv .dx-tab-selected .dx-tab-text {
    color: white !important;
}

.rightSidePanel {
    height: calc(100% - 1em);
    margin-bottom: 1em;
}

.mainSidebarDiv {
    display: flex;
    flex-direction: column;
    position: fixed; 
    height: calc(100% - 3.3em);
    width: 16em;
    float: left;
    z-index: 2;
}
.mainSidebarDiv .dx-empty-message {
    color: white !important;
}
.mainSidebarDiv .splitAddButton .dx-state-focused .dx-button {
    background-color: #5CB85C;
}
.mainSidebarDiv .splitAddButton .dx-button {
    background-color: #5CB85C;
    border-color: #5CB85C;
}
.mainSidebarDiv .splitAddButton .dx-button:focus {
    background-color: #5CB85C;
}
.mainSidebarDiv .splitAddButton .dx-button:hover {
    background-color: #45A046;
    border-color: #45A046;
}
.mainSidebarDiv .splitAddButton .dx-icon {
    color: white;
}

.mainSidebarHeaderDiv {
    width: 100%;
}

.sidebarCategoryTitle {
    width: 100%;
    margin: 0em;
    padding: 0em;
    padding-top: 0.5em;
    padding-bottom: 0.25em;
    padding-left: 0.5em;
    padding-right: 0.5em;
    color: #fff;
    vertical-align: middle;
    display: flex;
    flex-direction: row;
    gap: 0.5em;
    align-items: center;
}

.sidebarCategoryTitle .dx-button-content {
    padding: 0.4em;
}