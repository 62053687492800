.customChartWithButtonDiv {
    height: 100%;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
}

.customChartDiv {
    background-color: white;
    padding: 1em;
    padding-right: 2.25em;
    height: calc(100% - 2em);
    margin: 1em;
    flex-grow: 1;
    border-radius: 0.5em;
}

.customChartWithButtonDiv .dx-tabs-wrapper {
    display: flex;
}

.customChartWithButtonDiv .dx-tabpanel > .dx-tabpanel-tabs .dx-tab {
    /* Leave room for 1px-wide border */
    width: calc(25% - 0.25px);
}