.mobileViewerHeader.mobileReadOnlyGraphHeader {
    justify-content: space-between;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}

.mobileViewerHeader.mobileReadOnlyGraphHeader span {
    flex: 0 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mobileReadOnlyGraphRadioButtons {
    min-width: 9rem;
}