.uploadSetInputs {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.inputPropertyPopup .dx-texteditor.dx-state-readonly {
    background-color: rgb(246, 246, 246);
    border-color: rgb(200, 200, 200);
}