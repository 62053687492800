.virtualList {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.virtualListAutoSizer {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.virtualFixedSizeList {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.virtualFixedSizeList > div {
    overflow: hidden;
}

.virtualFixedSizeListLoadMoreDiv {
    text-align: center;
    width: 100%;
}

.virtualFixedSizeListLoadMoreDiv .dx-button {
    margin: 1rem;
    padding: 0.5rem;
}

.virtualFixedSizeListDraggingNumberCircle {
    border-radius: 50%;
    min-width: 1rem;
    height: 1rem;
    padding: 0.25rem;
    background: #fff;
    border: 0.1rem solid #666;
    color: #666;
    text-align: center;
    position: absolute;
    font-size: 0.75rem;
    top: -0.5rem;
    right: -0.5rem; 
}