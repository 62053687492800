.advancedSettingsButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0.2rem;
    border-radius: 1rem;
    background: linear-gradient(45deg, rgb(219, 219, 219), rgb(213, 230, 246));
    margin-left: 0.5rem;
    width: 1.75rem;
    height: 1.75rem;
}
.advancedSettingsButtonIcon {
    width: 100%;
    height: 100%;
}
.advancedSettingsButton path {
    fill: rgb(93, 93, 93);
}

.advancedSettingsButton:hover .advancedSettingsButtonIcon {
    opacity: 1;
    cursor: pointer;
    animation: spin 5s linear infinite;
}
.advancedSettingsButton:hover path {
    fill: rgb(48, 48, 48);
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.advancedSettingsPopupWrapper .dx-popup-content {
    padding: 0rem;
}
  
.advancedSettingsPopupWrapper .dx-popup-content-scrollable {
    overflow: visible;
}

.advancedSettings {
    display: grid;
    grid-template-columns: repeat(2, auto);
    grid-gap: 0.5em;
    padding: 1.5em;
}

.settingLabel {
    display: flex;
    align-items: center;
    padding-right: 1em;
}

.settingSelector {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5em;
}
.settingSelector .dx-selectbox {
    width: 14em;
}
.settingSelector .dx-numberbox {
    width: 14em;
}