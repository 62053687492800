.tagListItem {
    width: 100%;
    display: flex;
}

.tagListItemLeftDiv {
    width: 80%;
    display: flex;
    align-items: center;
}

.tagListItemRightDiv {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content:flex-end
}

.tagListItemRightDiv .dx-button {
    margin-left: 0.5vw;
}