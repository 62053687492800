.optionSelectorPopupContent {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    gap: 1em;
}

.optionsSelectorDiv {
    display: flex;
    flex-flow: column;
    height: 100%;
}

.optionsSelectorDiv .dx-dropdownbox {
    margin-bottom: 0.5rem;
}

.optionsSelectorDiv .dx-selectbox {
    margin-bottom: 0.5rem;
}

.optionsSelectorDiv .dx-textbox {
    margin-bottom: 0.5rem;
    font-size: small;
}

.optionsSelectorDiv .dx-textbox {
    margin-bottom: 0.5rem;
    font-size: small;
}

.optionsSelectorDiv .dx-checkbox {
    margin-right: 0.5rem;
    font-size: small;
}

.optionsSelectorDiv h5 {
    margin-top: 0.5rem;
    margin-bottom: 0.25rem;
    color: #347ab7;
    font-size: small;
}

.optionsSelectorDiv .dx-radiobutton {
    margin-left: 0.75rem;
    font-size: small;
}

.optionsSelectorDiv .dx-texteditor-input-container {
    font-size: small;
}

.optionsSelectorHeader {
    flex: 0 1 auto;
}

.optionsSelectorBody {
    flex: 1 1 auto;
    overflow-y: hidden;
    border-color: lightgray;
    border-style: solid;
    border-width: 0.05rem;
    border-radius: 0.25em;
}

.optionsSelectorBodySection {
    padding-left: 1em;
}

.optionsSelectorSectionsList {
    padding-bottom: 1rem;
}

.optionSelectorDropdownButtonDiv .dx-button {
    border-style: none;
    border-radius: 1rem;
    width: 2rem;
    height: 2rem;
    padding: 0rem;
}

.optionSelectorDropdownButtonDiv .dx-button .dx-icon {
    color: #999999;
}

.optionSelectorDropdownButton input {
    cursor: pointer;
}

.optionSelectorPopupWrapper .dx-popup-bottom.dx-toolbar {
    padding-top: 0em;
}
.optionSelectorPopupWrapper .dx-popup-content-scrollable {
    overflow: visible;
}
.optionSelectorPopupWrapper .dx-popup-content {
    padding: 1em;
}

.aggregationFunctionDiv {
    flex: 0 1 auto;
    padding-top: 0.5rem;
    min-height: 4.5em;
}

.aggregationFunctionSelectorDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    gap: 0.5em;
}
.aggregationFunctionSelectorDiv .dx-selectbox {
    margin-bottom: 0em;
}

.previewTableDiv {
    flex: 1 1 auto;
    overflow: hidden;
}

.optionSelectorDropdownButtonTextBox {
    margin: 0em !important;
}

.aggregationFunctionDescription {
    padding-top: 0.5em;
    font-style: italic;
}