.mobileViewer .viewerItem {
    flex: 0 1 auto;
    background-color: white;
    padding: 0.75rem;
    border-radius: 0.5rem;
    display: flex;
    gap: 0.5rem;
    font-size: 1.1em;
    font-weight: bold;
}

.mobileViewer .viewerItem span {
    flex: 0 1 auto;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mobileViewer .folderViewerItem {
    flex-direction: row;
    align-items: center;
}
.mobileViewer .folderViewerItem i {
    font-size: 1.4rem;
}

.mobileViewer .graphViewerItem {
    flex-direction: column;
}
.mobileViewer .graphViewerItem img {
    width: 100%;
    height: 8rem;
    padding: 0.5rem;
}