.columnBox {
    padding: 0.1em;
    padding-left: 0.5em;
    padding-right: 0.5em;
    border: solid 0.1em transparent;
    border-radius: 1em;
    background-color: #196977;
    color: white;
    user-select: none; 
    cursor: grab;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-basis: auto;
    font-style: normal;
}
.columnBox:hover {
    opacity: 0.9;
}

.numericColumnBox {
    background-color: #257425;
}

.unknownTypeColumnBox {
    background-color: rgb(117, 117, 120);
}

.readOnlyColumnBox {
    cursor: not-allowed;
}

.columnBoxSymbol {
    font-style: italic;
    font-size: 0.8em;
    padding-right: 0.4em;
    color: #37e1ff;
}
.numericColumnBox .columnBoxSymbol {
    color: #53ff53;
}
.unknownTypeColumnBox .columnBoxSymbol {
    color: #c8c8c8;
}