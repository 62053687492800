.dataTableWrapper {
    display: flex;
    flex-direction: column;
    padding: 0.75em;
    height: 100%;
}

.dataTableWrapper .categoryColumn {
    background-color: rgb(224, 232, 240);
}

.dataTableHeader {
    flex: 0 1 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 0.5em;
}

.dataTable {
    flex: 1 1 auto;
    height: 100%;
    overflow: hidden;
}

.dataTableHeaderLeft {
    display: flex;
    flex-direction: row;
    gap: 0.75em;
    align-items: center;
}

.addCategoryButtonPopoverList {
    display: flex;
    flex-direction: column;
}

.addCategoryButtonPopoverListItem {
    padding: 0.75em 0.5em;
    user-select: none;
}
.addCategoryButtonPopoverListItemWithBorder {
    border-top: 0.1em rgb(226, 226, 226) solid;
}
.addCategoryButtonPopoverListItem:hover {
    background-color: rgb(241, 241, 241);
    cursor: pointer;
}

.addCategoryPopoverWrapper .dx-popup-content {
    padding: 0.5em;
}

.columnSelectionBox {
    display: flex;
    flex-direction: row;
    gap: 0.5em;
    align-items: center;
    justify-content: center;
    padding: 0.25em 0.5em;
    border-radius: 0.5em;
}

.dataTableHeaderRight {
    display: flex;
    flex-direction: row;
    gap: 0.5em;
}

.dataTable .dx-datagrid-headers {
    background-color: #eeeeee;
}

.dataTable .dx-header-row {
    color:black;
}

.dataTable .columnHeaderDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5em;
}

.dataTable .columnActionButton {
    background-color: white;
    min-width: 1.6em;
    min-height: 1.6em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 0.1rem solid transparent;
}
.dataTable .columnActionButton:hover {
    cursor: pointer;
    opacity: 80%;
}
.dataTable .columnActionButton:hover i {
    cursor: pointer;
    opacity: 80%;
}

.dataTable .buttonHasFilters {
    border-color: #337AB7;
}
.dataTable .buttonHasFilters i {
    color: #337AB7;
}