.folderNavigationDiv {
    display: flex;
    background: whitesmoke;
    margin: 0.25em;
    padding: 0.25em;
    border-width: 0.1em;
    border-radius: 0.25em;
    border-color: gray;
    border-style: solid;
    user-select: none;
    width: calc(100% - 0.5em);
    flex-wrap: wrap;
}

.breadCrumbButton {
    max-width: 7em;
}

.breadCrumbButton .dx-button-content {
    padding: 0.3em;
    text-overflow: ellipsis;
}

.breadCrumbArrow {
    border-style: none;
    background-color: transparent;
}

.breadCrumbArrow .dx-button-content {
    margin: 0.025em;
    padding: 0em;
}

.breadCrumbArrow .dx-button-content .dx-icon {
    opacity: 1;
}