.mainCompaniesDiv {
    display: flex;
    height: 100%;
}

.mainCompaniesDiv .dx-list {
    height: 90%;
}

.mainCompaniesDiv h3 {
    margin-top: 0vh;
}

.mainCompaniesDiv .dx-button-content {
    padding: 0.65vh !important;
}

.companiesDiv {
    width: 40%;
}

.companiesDiv .dx-empty-message {
    white-space: pre-wrap;
    color: #a0a0a0;
}

.companyUsersDiv {
    padding-left: 1vw;
    width: 60%;
}

.addUserByEmailPopupWrapper .dx-popup-content {
    padding-bottom: 0rem;
}
  
.addUserByEmailPopupWrapper .dx-popup-content-scrollable {
    overflow: visible;
}