.otherInformationTabPanel .notesTextArea {
    height: 15.5rem;
    margin-left: 1rem;
    margin-right: 1rem;
}

.otherInformationTabPanel {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.otherInformationTabPanel .dx-multiview-wrapper {
    border-width: 0rem;
}  

.otherInformationTabPanel .dx-fieldset {
    margin: 1rem;
}

.otherInformationTabPanel .dx-tab-selected .dx-tab-text {
    color: white !important;
}

.otherInformationTabPanel h4 {
    margin: 0rem;
    margin-left: 1rem;
    margin-bottom: 0.5rem;
}

.notesSaveButton {
    position: absolute;
    right: 1rem;
    bottom: 1rem;
}

/* PG Note: This affects ALL popup titles. It would be good if I could set this only where I need to. But the popup html seems to just get put
in the <body> at the top level, so I don't know how to target it. */
.dx-popup-title .dx-toolbar-before {  
    width: 100%;
}  

.otherInformationTabPanel .mainDataLabel {
    width: 22%;
}

.otherInformationTabPanel .mainDataTextBox {
    width: calc(100% - 22%) !important;
}

.structuresTableDiv {
    height: 100%;
    overflow: hidden;
}
.structuresTable {
    height: 100%;
    padding: 0.75rem;
}