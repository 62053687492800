.uploadSetFilterableList {
    width: calc(100% - 2em);
    height: calc(100% - 2em);
    margin: 1em;
    display: flex;
    flex-flow: column;
}

.uploadSetFilterableListHeader {
    flex: 0 1 auto;
}

.uploadSetFilterableListHeader > h3 {
    margin: 0em;
    margin-bottom: 0.5em;
}

.uploadSetFilterableListHeader > h3 .dx-button {
    margin-left: 0.65em;
}

.uploadSetFilterableListHeader > h3 .dx-button-content {
    padding: 0.4em;
}

.uploadSetFilterableListContent {
    flex: 1 1 auto;
    background-color: rgb(252, 252, 252);
    border-bottom-style: dotted;
    border-bottom-width: 0.01em;
    border-bottom-color: gray;
    overflow: hidden;
    position: relative;
}

.uploadSetListItem {
    width: 100%;
    height: 100%;
    border-style: solid;
    border-width: 0.075rem;
    border-color: #ddd;
    border-radius: 0.5em;
    padding: 0.5em;
    padding-bottom: 0.25em;
    display: flex;
    flex-flow: column;
    overflow: hidden;
    box-sizing: border-box;
}
.uploadSetListItem:hover {
    cursor: grab;
}

.uploadSetListItemTitle {
    flex: 0 1 auto;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline;
    overflow: hidden;
}

.uploadSetListItemDescription {
    flex: 0 1 auto;
    white-space: nowrap;
    width: 100%;
    display: inline;
    text-overflow: ellipsis;
    overflow: hidden;
}

.uploadSetTagBox {
    flex: 0 1 auto;
    white-space: nowrap;
    width: 100%;
    padding: 0.1em;
    display: inline;
    overflow: hidden;
}

.uploadSetTagBoxEmptyMessage {
    margin-top: 0.25em;
    font-size: small;
    color: gray;
    overflow: hidden;
}

.uploadSetTag {
    background-color: rgb(225, 225, 225);
    display: inline-block;
    user-select: none;
    width: auto;
    max-width: 12em;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
    border-radius: 4%;
    font-size: small;
    margin: 0.1em;
    padding: 0.1em;
    overflow: hidden;
}

.searchTextBox {
    width: 100%;
    display: flex;
    vertical-align: middle;
}

.searchTextBox .dx-button {
    border-radius: 50%;
}