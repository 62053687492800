.quickComparePopup {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.quickComparePopup .dx-datagrid .dx-header-row .dx-datagrid-text-content {
    color: black;
}

.quickComparePopup .quickCompareHeader {
    text-align: left;
}
.quickComparePopup .quickCompareHeader .dx-checkbox {
    padding-left: 0.25rem;
    padding-right: 0.75rem;
}

.quickComparePopup .quickCompareTable {
    flex: 1 1 auto;
    overflow-y: hidden;
}