.filterUsageOverview {
    background-color: white;
    margin: 0.75rem;
}

.filterUsageOverview .filters {
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow: hidden;
}

.filterUsageOverview .counts {
    width: 100%;
    display: inline-flex;
    flex-direction: row;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    justify-content: space-around;
}

.filterUsageOverview .count {
    display: inline-flex;
    align-items: center;
}

.filterUsageOverview .countTextBox {
    width: 4rem;
    height: 2.25rem;
}

.filterUsageOverview .countLabel {
    width: auto;
    padding-right: 0.5rem;
}

.filterUsageOverview .filterHeader {
    margin-bottom: 0rem;
    margin-top: 0.75rem;
    margin-left: 1rem;
    margin-right: 0.75rem;
}

.filterUsageOverview .tagBox {
    flex: 1 1 auto;
    margin-right: 1rem; 
    margin-top: 0.75rem;
    overflow: hidden;
}

.filterUsageOverview .tagBox .dx-tag-container {
    padding: 0rem;
}

.filterUsageOverview .tagBox .dx-tag-content {
    max-width: 20rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.filterUsageOverview .loadingIndicatorDiv {
    width: 4rem;
    height: 2.25rem;
    display: block;
}
.filterUsageOverview .loadingIndicator {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}