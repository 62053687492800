.moreLessButton {
    margin-top: 0.75vh;
    width: 7vw;
}

/* List item */
.popupList > .dx-scrollable-wrapper > .dx-scrollable-container > .dx-scrollable-content > .dx-scrollview-content > .dx-item {  
    border: 0px;  
    width: 33.33%;  
    float: left;  
    margin: 0px;
    padding: 0px !important;
}  

/* Popup list item content text */
.popupList .dx-scrollable-wrapper .dx-scrollable-container .dx-scrollable-content .dx-scrollview-content .dx-item .dx-item-content > div > div {
    text-overflow:ellipsis;
    overflow: hidden;
}

/* List item */
.filterList > .dx-scrollable-wrapper > .dx-scrollable-container > .dx-scrollable-content > .dx-scrollview-content > .dx-item {
    border: 0px !important;   
    margin: 0px !important;
    padding: 0px !important;
    width: 100%;
}

/* List item content */
.filterList > .dx-scrollable-wrapper > .dx-scrollable-container > .dx-scrollable-content > .dx-scrollview-content > .dx-item > .dx-item-content {
    padding: 0.5vh;
    color: #fff;
}

/* List item content text */
.filterList .dx-scrollable-wrapper .dx-scrollable-container .dx-scrollable-content .dx-scrollview-content .dx-item .dx-item-content > div > div {
    text-overflow:ellipsis;
    overflow: hidden;
}

.filterList .dx-empty-message {
    color: white !important;
}