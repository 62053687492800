.userFeaLoadPropertyPopup {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.userFeaLoadPropertyTopOptions {
    display: flex;
    flex-direction: row;
    margin: 0.5rem;
}
.userFeaLoadPropertyTopOptions .dx-radiogroup.dx-state-readonly .dx-radiobutton-icon::before {
    border-color: lightgray;
}
.userFeaLoadPropertyTopOptions h4 {
    padding: 0rem;
    margin: 0rem;
    margin-right: 0.5rem;
    font-size: 1rem;
}
.feaLoadPropertyRadioButtons {
    flex: 0 1 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.5rem;
}

.userFeaLoadPropertyContent {
    flex: 1 1 auto;
    overflow-y: hidden;
}
.userFeaLoadPropertyContent .dx-datagrid {
    border-color: lightgray;
    border-style: solid;
    border-width: 0.05rem;
}
.userFeaLoadPropertyContent .dx-datagrid .dx-header-row .dx-datagrid-text-content {
    color: black;
}