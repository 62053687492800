.filterCategory {
    padding: 1vh;
    padding-top: 0vh;
    width: 100%;
}

.filterCategoryTitle {
    margin: 0vw;
    margin-top: 1vh;
    margin-bottom: 0.5vh;
    padding: 0.5vh;
    color: #fff;
    border-top: #fff;
    border-top-style: ridge;
    border-top-width: 0.25vh;
}

.scrollViewer {
    height: 100%;
    width: 100%;
}

.mainFilterDiv {
    display: flex;
    flex-direction: column;
    position: fixed;
    height: calc(100% - 3.3em);
    padding: 0vh;
    width: 17em;
}