.invitesDiv {
    width: 100%;
    height: 100%;
}

.inviteListItem {
    width: 100%;
    display: flex;
    margin-top: -0.75rem;
    margin-bottom: -0.75rem;
}

.inviteListItemLeftDiv {
    flex-direction: column;
    align-items: flex-start;
    width: 80%;
}

.inviteListItemRightDiv {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content:flex-end
}

.inviteListItemRightDiv .dx-button {
    margin-left: 0.5vw;
}

#acceptButton .dx-icon {
    color: #FFFFFF;
}

#declineButton .dx-icon {
    color: #FFFFFF;
}


.addProgramUsersTabPanel .dx-tab-selected .dx-tab-text {
    color: white !important;
}

.invitesDiv .dx-tabs-wrapper {
    display: flex;
}

.invitesDiv .dx-tabpanel > .dx-tabpanel-tabs .dx-tab {
    /* Leave room for 1px-wide border */
    width: calc(50% - 0.5px);
}

.panelItemDiv {
    height: 100%;
    padding: 1vh;
}