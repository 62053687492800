.chartNotesDiv {
    width: 100%;
    height: 100%;
    padding: 1vh;
    background-color: white;
}

.chartNotesDiv .notesTextArea {
    height: 100%;
    padding-bottom: 4vh;
    border: 0vw !important;
}

.chartNotesDiv span {
    position: absolute;
    bottom: 0vh;
    right: 0vh;
    margin: 2vh;
    color: gray;
    font-style: italic;
}
