.sizingOverridesPopup {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
}

.sizingOverridesPopup .dx-datagrid .dx-header-row > td {
    vertical-align: middle!important;
    font-weight: 500;
}

.sizingOverridesPopup .defaultHeader {
    background-color: #ADD8E6;
    border-left: none !important;
    border-right: none !important;
    padding: 0.25rem !important;
    text-align: right !important;
}
.sizingOverridesPopup .defaultHeader .dx-datagrid-text-content {
    color: slategray;
}

.sizingOverridesPopup .boldHeader .dx-datagrid-text-content {
    color: black;
}
.sizingOverridesPopup .boldHeader[role=columnheader] {  
    text-align: center!important;
}