.columnFilterPopupWrapper .dx-popup-content {
    padding: 0rem;
    overflow: hidden;
}

.columnFilterPopup {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0.5em;
}